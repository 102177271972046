import { useLotteryList } from '../../hooks/useLotteryList'
import { Box, Grid, Pagination, Stack, Typography } from '@mui/material'
import LotteryCard from './LotteryCard'
import { useState } from 'react'
import img from '../../assets/images/img.png'
import img1 from '../../assets/images/img_1.png'
import img2 from '../../assets/images/img_2.png'
import img3 from '../../assets/images/img_3.png'
import noData from '../../assets/images/noData.png'
import loading from '../../assets/images/loading.png'
import { SwitchTabWrapper, Tab } from '../../components/SwitchTab'

function Loading() {
  return (
    <Box
      sx={{
        borderRadius: '20px',
        background: '#F3F3F3',
        width: '100%',
        height: 544,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
      }}
    >
      <Stack direction={'row'} alignItems={'center'}>
        <img style={{ width: 57 }} src={loading} />
        <Typography ml={10}>Loading</Typography>
      </Stack>
    </Box>
  )
}

function NoData() {
  return (
    <Box
      sx={{
        borderRadius: '20px',
        background: '#F3F3F3',
        width: '100%',
        height: 544,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
      }}
    >
      <Stack direction={'row'} alignItems={'center'}>
        <img style={{ width: 40 }} src={noData} />
        <Typography ml={10}>No Data</Typography>
      </Stack>
    </Box>
  )
}

export default function Lottery() {
  const [page, setPage] = useState(1)
  const [isAll, setIsAll] = useState(true)
  const { lotteries, total, loading: isLoading } = useLotteryList(page, isAll)

  return (
    <Box padding={50} width={'100%'}>
      <Stack mb={50} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography fontFamily={'Poppins'} fontSize={48} fontWeight={600}>
          Antimatter Random
        </Typography>
        <img style={{ width: 500 }} src={img} />
      </Stack>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <SwitchTabWrapper style={{ margin: '20px 0' }}>
            <Tab
              style={{ width: 100 }}
              onClick={() => {
                setPage(1)
                setIsAll(true)
              }}
              selected={isAll}
            >
              All
            </Tab>
            <Tab
              style={{ width: 130 }}
              onClick={() => {
                setPage(1)
                setIsAll(false)
              }}
              selected={!isAll}
            >
              My Participation
            </Tab>
          </SwitchTabWrapper>
          {lotteries?.length && lotteries.length > 0 ? (
            <>
              <Grid container spacing={20}>
                {lotteries?.map(lottery => {
                  return (
                    <Grid item xs={12} md={6} lg={4} key={lottery.poolId}>
                      <LotteryCard lottery={lottery} />
                    </Grid>
                  )
                })}
              </Grid>
              {lotteries?.length && (
                <Pagination
                  page={page}
                  onChange={(_, value) => {
                    console.log('value', value)
                    setPage(value)
                  }}
                  style={{ marginTop: 20 }}
                  count={Math.ceil(total / 12)}
                  variant="outlined"
                  shape="rounded"
                />
              )}
              <Stack mt={20} direction={'row'} justifyContent={'space-around'} alignItems={'center'}>
                <img style={{ width: 50, height: 58 }} src={img1} />
                <img style={{ width: 102, height: 101 }} src={img2} />
                <img style={{ width: 112, height: 110 }} src={img3} />
              </Stack>
            </>
          ) : (
            <NoData />
          )}
        </>
      )}
    </Box>
  )
}
