import { Chain } from 'models/chain'
import { ReactComponent as ETH } from 'assets/svg/chain_swap.svg'
import EthUrl from 'assets/svg/eth_logo.svg'

export enum ChainId {
  MAINNET = 20221
}

export const ChainList = [
  {
    icon: <ETH />,
    logo: EthUrl,
    symbol: 'Matter',
    name: 'B2',
    id: ChainId.MAINNET,
    hex: '0x4EFD'
  }
]

export const ChainListMap: {
  [key: number]: { icon: JSX.Element; link?: string; selectedIcon?: JSX.Element } & Chain
} = ChainList.reduce((acc, item) => {
  acc[item.id] = item
  return acc
}, {} as any)

export const SUPPORTED_NETWORKS: {
  [chainId in ChainId]?: {
    chainId: string
    chainName: string
    nativeCurrency: {
      name: string
      symbol: string
      decimals: number
    }
    rpcUrls: string[]
    blockExplorerUrls: string[]
  }
} = {
  [ChainId.MAINNET]: {
    chainId: '0x4EFD',
    chainName: 'B2',
    nativeCurrency: {
      name: 'B2',
      symbol: 'Matter',
      decimals: 18
    },
    rpcUrls: ['https://bastest-rpc.antimatter.finance'],
    blockExplorerUrls: ['https://bastest-explorer.antimatter.finance/']
  }
}
